
import AuthService from "@/auth/AuthService";
import OJ7040S from "@/service/OJ7040Service";
import ZFunc from  "@/service/ZFunc"
import { Vue } from "vue-class-component";
import zCONST from "@/const";

export default class OJ7040F extends Vue {
    // クラス定義
    AuthS: AuthService = new AuthService();
    OJ7040S: OJ7040S = new OJ7040S();
    ZF: ZFunc = new ZFunc();

    // 検索フィールド変数
    mRefSAJYNM: any = "";       // ユーザ名

    mUSERKY: any = 0;
    mTOKUKY: any = 0;

    // 一覧フィールド変数
    mDgList: any[] = [];            // データグリッドソース
    loadingFlg: boolean = false;    // ロード中フラグ

    // 変数定義
    $cookies: any;    // cookie

    mounted(): void {
        this.fncInit();
    }

    // 初期表示処理
    fncInit(): void {
        this.fncClear();
        this.mUSERKY = this.$cookies.get("USERKY");

        //ログイン情報取得
        let wParam = { USERKY: this.mUSERKY };
        this.OJ7040S.fncGetUserInfo(wParam).then((data: any) => {
            this.mTOKUKY = data.TOKUKY;
            this.fncGetList();
        });
    }

    // 初期化処理
    fncClear(): void {
        this.mRefSAJYNM = "";
    }

    // 斎場リスト取得処理
    fncGetList(): void {
        let wParam = { 
            TOKUKY: this.mTOKUKY
            ,SAJYNM: this.mRefSAJYNM
        };
        this.loadingFlg = true;
        this.OJ7040S.fncGetList(wParam).then((data: any) => {
            this.mDgList = data.dgList;
        }).finally(() => {this.loadingFlg = false;});
    }

    fncClearButton(): void{
        this.mRefSAJYNM ="";
        this.fncGetList();
    }

    fncGetMap(pMAPURL:any){
        if(pMAPURL == ''){
            return '';
        }else{
            return '地図';
        }
    }

     //リンク押下
    fncClickLink(pLINKAD:any){
        //console.log(pLINKAD)
        window.open(pLINKAD, "_blank");
    }

    // 詳細表示
    fncShowShosai(pSAJYKY: number): void {
        // 詳細画面を新規モードで呼び出す
        const zZ7041F: any = this.$refs["OJ7041F"];
        let wMODEXX: number;
        if (pSAJYKY == 0) {
            wMODEXX = zCONST.cMODE_ADD;
        } else {
            wMODEXX = zCONST.cMODE_DISP;
        }

        //ダイアログを表示
        zZ7041F.fncShow(
        {
            MODEXX: wMODEXX,
            SAJYKY: pSAJYKY,
        },
        () => {
            //登録成功
            this.fncGetList();
        }
        );
    }
}
